import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'core/components/Icon.js';
import Answer from 'professional/components/Answer.js';

export default class QA extends React.Component {
    static propTypes = {
        answers: PropTypes.arrayOf(
            PropTypes.shape({
                body: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                question: PropTypes.string,
                subject: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }),
        ),
        avatar: PropTypes.string,
        initials: PropTypes.string,
        profileId: PropTypes.number.isRequired,
        shouldShowMoreButton: PropTypes.bool.isRequired,
        showMoreCallback: PropTypes.func.isRequired,
        totalAgrees: PropTypes.number,
        totalContributions: PropTypes.number,
        totalThanks: PropTypes.number,
    };

    render() {
        const {
            answers,
            avatar,
            totalContributions,
            initials,
            profileId,
            shouldShowMoreButton,
            showMoreCallback,
            totalAgrees,
            totalThanks,
        } = this.props;

        const avatarData = {
            avatar,
            initials,
            profileId,
        };

        return (
            <>
                <ul className="summary">
                    <li>
                        <strong>
                            <Icon name="answers" />
                            {totalContributions}
                        </strong>
                        {'answers to member questions'}
                    </li>
                    <li>
                        <strong>
                            <Icon name="agrees" />
                            {totalAgrees}
                        </strong>
                        {'practitioners have agreed'}
                    </li>
                    <li>
                        <strong>
                            <Icon name="thanks" />
                            {totalThanks}
                        </strong>
                        {'thanks from members'}
                    </li>
                </ul>
                <ol className="answers">
                    {answers.map((answer) => (
                        <Answer
                            answer={answer}
                            avatar={avatarData}
                            key={answer.id}
                        />
                    ))}
                </ol>
                {shouldShowMoreButton && (
                    <button className="cta" onClick={showMoreCallback}>
                        Load more
                    </button>
                )}
            </>
        );
    }
}
