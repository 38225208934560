import React from 'react';
import ReactDOM from 'react-dom';

import events from 'events.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

import CombinedProfileApp from 'professional/components/CombinedProfileApp.js';

(async function () {
    await events.ready();

    const profileSection = document.querySelector('#professional-profile');
    const profile = profileSection.dataset.profile;
    const promotedGapSchemes = profileSection.dataset.promotedGapSchemes;
    const promotions = profileSection.dataset.promotions;
    const {skin, userType} = document.body.dataset;
    const props = {
        ...snakeToCamelObjectKeys(JSON.parse(profile)),
        globalPromotedGapSchemes: JSON.parse(promotedGapSchemes),
        skin,
        userType,
    };
    if (promotions?.length) {
        props.promotions = JSON.parse(promotions).map((promotion) =>
            snakeToCamelObjectKeys(promotion),
        );
    }

    ReactDOM.render(<CombinedProfileApp {...props} />, profileSection);
})();
