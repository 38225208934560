import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'core/components/Icon.js';

const bedsideMannerTip =
    'How well the practitioner listens to and understands the issues of the patient';
const conditionUnderstandingTip =
    "How well the practitioner explains a patient's condition to them";
const treatmentCommunicationTip =
    'How well the practitioner communicates a treatment plan to the patient';

export default class Ratings extends React.Component {
    static propTypes = {
        bedsideManner: PropTypes.string.isRequired,
        conditionUnderstanding: PropTypes.string.isRequired,
        numberOfRatings: PropTypes.number.isRequired,
        rating: PropTypes.string.isRequired,
        treatmentCommunication: PropTypes.string.isRequired,
        wouldRecommend: PropTypes.number.isRequired,
    };

    trim(rating) {
        return rating.replace('.0', '');
    }

    renderOverview() {
        const {numberOfRatings, rating, wouldRecommend} = this.props;
        return (
            <ul className="ratings-overview">
                <li data-ratings={rating}>
                    <Icon name="ratings" />
                    {`(${this.trim(rating)}/5 stars)`}
                </li>
                <li>
                    <strong>{numberOfRatings}</strong>
                    {'ratings'}
                </li>
                <li>
                    <strong>{`${wouldRecommend}%`}</strong>
                    {'would recommend'}
                </li>
            </ul>
        );
    }

    renderBreakdown() {
        const {
            bedsideManner,
            conditionUnderstanding,
            treatmentCommunication,
        } = this.props;
        return (
            <dl className="ratings-breakdown">
                <dd data-ratings={bedsideManner}>
                    <Icon name="ratings" />
                    {`(${this.trim(bedsideManner)}/5 stars)`}
                </dd>
                <dt>
                    {'Bedside manner'}
                    <em>{bedsideMannerTip}</em>
                </dt>
                <dd data-ratings={conditionUnderstanding}>
                    <Icon name="ratings" />
                    {`(${this.trim(conditionUnderstanding)}/5 stars)`}
                </dd>
                <dt>
                    {'Condition understanding'}
                    <em>{conditionUnderstandingTip}</em>
                </dt>
                <dd data-ratings={treatmentCommunication}>
                    <Icon name="ratings" />
                    {`(${this.trim(treatmentCommunication)}/5 stars)`}
                </dd>
                <dt>
                    {'Treatment communication'}
                    <em>{treatmentCommunicationTip}</em>
                </dt>
            </dl>
        );
    }

    renderPolicyNote() {
        return (
            <article>
                <h2>{'HealthShare practitioner ratings policy'}</h2>
                <p>
                    {
                        'HealthShare offers a Practitioner Ratings feature to allow patients to highlight their experiences with their practitioner post-consultation.'
                    }
                </p>
                <p>
                    {'The service has been designed to comply with the '}
                    <a href="https://www.ahpra.gov.au/Publications/Advertising-resources/Check-and-correct/Testimonial-tool.aspx">
                        {
                            "Australian Health Practitioner Regulation Agency's (AHPRA) rules regarding patient testimonials."
                        }
                    </a>
                    {
                        ' Only patients that have recently been for a consultation are able to submit a rating. No free-text feedback will be displayed publicly, but practitioners will receive a summary of all patient feedback in their private HealthShare dashboard.'
                    }
                </p>
            </article>
        );
    }

    render() {
        return (
            <>
                {this.renderOverview()}
                {this.renderBreakdown()}
                {this.renderPolicyNote()}
            </>
        );
    }
}
