import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';
import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';
import Tools from 'core/components/Tools';
import {elementHasRightOverflow} from 'core/utils.js';
import events from 'events.js';
import {PAGE_TYPE_PRACTITIONER} from 'core/constants.js';

export default class Tabs extends React.Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        client: PropTypes.string,
        displayName: PropTypes.string.isRequired,
        parentRef: PropTypes.shape({current: PropTypes.instanceOf(Element)}),
        pdfUrl: PropTypes.string.isRequired,
        practices: PropTypes.arrayOf(PropTypes.object),
        ratings: PropTypes.shape({
            rating: PropTypes.string.isRequired,
            numberOfRatings: PropTypes.number.isRequired,
        }),
        tabs: PropTypes.array.isRequired,
        totalContributions: PropTypes.number,
    };

    constructor(props) {
        super(props);

        this.tabsTitles = {
            'locations': 'Locations',
            'overview': 'Overview',
            'fees': 'Fees',
            'qa': 'Q&A',
            'ratings': 'Ratings',
            'related-info': 'Related info',
        };
        this.tabsRef = React.createRef();
        this.state = {
            hasOverflow: false,
        };
        this.overflowClass = 'tabs-overflow';
    }

    componentDidMount() {
        events.listen(
            window,
            ['resize', 'orientationchange'],
            this.handleTabsScroll,
        );

        if (this.tabsRef) {
            events.listen(
                this.tabsRef.current,
                ['scroll'],
                this.handleTabsScroll,
            );
        }
    }

    componentWillUnmount() {
        events.unlisten(
            window,
            ['resize', 'orientationchange'],
            this.handleTabsScroll,
        );

        if (this.tabsRef) {
            events.unlisten(
                this.tabsRef.current,
                ['scroll'],
                this.handleTabsScroll,
            );
        }
    }

    @autobind
    handleTabsScroll() {
        if (this.tabsRef?.current && this.props.parentRef?.current) {
            const tabs = this.tabsRef.current;
            const overflow = elementHasRightOverflow(tabs);
            this.toggleOverflowClass(overflow);
        }
    }

    toggleOverflowClass(hasOverflow) {
        const cardProfile = this.props.parentRef.current;

        if (hasOverflow) {
            if (!cardProfile.classList.contains(this.overflowClass)) {
                cardProfile.classList.toggle(this.overflowClass);
            }
        } else {
            if (cardProfile.classList.contains(this.overflowClass)) {
                cardProfile.classList.toggle(this.overflowClass);
            }
        }

        this.setState({hasOverflow});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tabs !== this.props.tabs) {
            this.handleTabsScroll();
        }
        if (prevProps.activeTab === '' && this.props.activeTab) {
            const activeTab = document.getElementById(
                `${this.props.activeTab}-tab`,
            );
            this.scrollToTab(activeTab);
        }
    }

    renderTabTitle(tab) {
        const {ratings, totalContributions} = this.props;
        if (tab === 'ratings') {
            return (
                <>
                    {`${this.tabsTitles[tab]}`}
                    <span className="count">
                        {`(${ratings.numberOfRatings})`}
                    </span>
                    <Icon name="ratings" />
                </>
            );
        }
        if (tab === 'qa') {
            return (
                <>
                    {`${this.tabsTitles[tab]}`}
                    <span className="count">{` (${totalContributions})`}</span>
                </>
            );
        }
        if (tab === 'locations') {
            const pLength = this.props.practices.length;
            const countText = ` (${pLength ? pLength : ''})`;
            const tabTitle = this.tabsTitles[tab].slice(0, -1);
            return (
                <>
                    {`${tabTitle}${pLength === 1 ? '' : 's'}`}
                    <span className="count">{countText}</span>
                </>
            );
        }
        return this.tabsTitles[tab];
    }

    @autobind
    handleClick(event, tab) {
        this.scrollToTab(event.target);
    }

    scrollToTab(tabEl) {
        if (tabEl.parentNode.scrollBy) {
            const {left, width} = tabEl.getBoundingClientRect();
            tabEl.parentNode.scrollBy(
                left - window.innerWidth / 2 + width / 2, // eslint-disable-line no-mixed-operators
                0,
            );
        }
    }

    render() {
        const {activeTab, client, pdfUrl, displayName, tabs} = this.props;

        return (
            <nav className="tabs" ref={this.tabsRef} role="tablist">
                {tabs.map((tab) => {
                    let customClass, dataRating;
                    if (tab === 'ratings') {
                        customClass = 'ratings';
                        dataRating = this.props.ratings.rating;
                    }
                    return (
                        <Link
                            actionWithEvent={(event) =>
                                this.handleClick(event, tab)
                            }
                            controls={`${tab}-panel`}
                            customClass={customClass}
                            data={{ratings: dataRating}}
                            href={`#${tab}`}
                            id={`${tab}-tab`}
                            key={tab}
                            role="tab"
                            selected={activeTab === tab}
                        >
                            {this.renderTabTitle(tab)}
                        </Link>
                    );
                })}
                <span />
                <Tools
                    availableCategories={['Address', 'Phone number', 'Other']}
                    customClass={
                        this.state.hasOverflow ? this.overflowClass : ''
                    }
                    displayName={displayName}
                    isClient={!!client}
                    pageType={PAGE_TYPE_PRACTITIONER}
                    pdfUrl={pdfUrl}
                />
            </nav>
        );
    }
}
