const scrollDic = {
    'procedure_fees': {
        tab: 'fees',
        target: 'procedure_fees',
    },
};

const getScrollTarget = function (scrollSectionName) {
    const scrollSection = scrollDic[scrollSectionName];
    if (scrollSection) {
        return {
            tab: scrollSection.tab,
            target: scrollSection.target,
        };
    }
};

export {getScrollTarget};
